import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    saveProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCardTask', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateCardUserList(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardUserList', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

    updateProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardTask', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectName(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardName', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardClient', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    
    archiveProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('archiveCard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

    updateProjectDescription(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardDescription', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskDueDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardTaskDueDate', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskAssigneUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardTaskAssigneUser', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskPriority(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardTaskPriority', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskIsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardTaskIsCompleted', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectRoles(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardRoles', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectContacts(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardContacts', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getCards', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjectFolders(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getCardFolders', { params: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjectNotes(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getCardNotes', { params: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCardFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCardFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveProjectNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCardNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectNote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardNote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateProjectStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateCardStatus', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    updateProjectFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateCardFile', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectFolder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put('updateCardFolder', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    dropdownUserAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownUserAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    dropdownCompanyAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('dropdownCompanyAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getProjectById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getCardById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('cardUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    projectContactList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('cardContactList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    projectContactClient(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .post('cardContactClient', queryParams)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    
    getProjectClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getCardClients', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getProjectDataFromClockify(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getCardDataFromClockify', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientIdProjectIdReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getClientIdCardIdReports', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getClientAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getClientAll', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createProjectBoard(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('createCardBoard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    editProjectBoard(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('editCardBoard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteProjectBoard(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('deleteCardBoard', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    getProjectBoards(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getCardBoards', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getClientIdProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getClientIdCards`, { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    
  },
};
